import axios from '../../helpers/request'
import './header.css'
import {DataGrid} from '@material-ui/data-grid'
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
  AddOutlined,
  LayersOutlined,
  NotificationsNone
} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useAppState} from '../../state'
import logoutIcon from '../../components/images/logoutivon.svg'
import settingsIcon from '../../components/images/settings.svg'
import profileIcon from '../../components/images/profile.svg'
import helpIcon from '../../components/images/help.svg'
import pricingIcon from '../../components/images/pricingicon.svg'
import feedbackIcon from '../../components/images/feedback.svg'
import channelIcon from '../../components/images/channels.svg'
import activityIcon from '../../components/images/activity.svg'
import userIcon from '../../components/images/usermainicon.svg'

export default function Headermenus(props) {
  const {user, signOut} = useAppState()
  const {setOpenProfile} = props

  return (
    <div id="dropdown" class="z-10 absolute right-7 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
        <li>
          <Link
            className="flex justify-start   px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/profile"
            onClick={() => {
              setOpenProfile(false)
            }}
          >
            {' '}
            <img src={profileIcon} alt="profile" className="pr-3" />
            Profile
          </Link>
        </li>
        <li>
          <Link
            className="flex justify-start  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/Logs"
            onClick={() => {
              setOpenProfile(false)
            }}
          >
            {' '}
            <img src={activityIcon} alt="activity-log" className="pr-3" />
            Activity Log
          </Link>
        </li>
        <li>
          <Link
            className="flex justify-start  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/users"
            onClick={() => {
              setOpenProfile(false)
            }}
          >
            {' '}
            <img src={userIcon} alt="user-management" className="pr-3" />
            User Management
          </Link>
        </li>
        <li>
          <Link
            className="flex justify-start  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/support"
            onClick={() => {
              setOpenProfile(false)
            }}
          >
            {' '}
            <img src={helpIcon} alt="help-support" className="pr-3" />
            Help & Support
          </Link>
        </li>
        <li>
          <Link
            className="flex justify-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/settings"
            onClick={() => {
              setOpenProfile(false)
            }}
          >
            {' '}
            <img src={settingsIcon} alt="settings" className="pr-3" />
            Settings
          </Link>
        </li>
        <li>
          <Link
            className="flex justify-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            to="/settings"
            onClick={() => {
              setOpenProfile(false)
              signOut()
            }}
          >
            {' '}
            <img src={logoutIcon} alt="logout" className="pr-3" />
            Log Out
          </Link>
        </li>
      </ul>
    </div>
  )
}
