import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useParams, useLocation} from 'react-router-dom'
import Switch from '@material-ui/core/Switch'
import {Breadcrumbs, Typography} from '@material-ui/core'
import Swal from 'sweetalert2'
import axios from '../../../helpers/request'
import './newCustomField.css'
import {Grid} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import folderIcon from '../../images/folder-icon.svg'

export default function NewContact() {
  const fileReader = new FileReader()
  const browseFileRef = useRef()
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const isEditable = location.pathname.split('/').includes('custom-field-edit')
  const [loading, setLoading] = useState(false)
  const [mappingExist, setMappingExist] = useState(false)
  const [file, setFile] = useState(null)
  const [mappings, setMappings] = useState([])
  const [fieldData, setData] = useState({
    name: '',
    displayName: '',
    mandatory: true,
    showOnDashboard: false,
    uploadMapping: false
  })

  useEffect(() => {
    if (isEditable && params.id) {
      getFieldDetail()
    }
  }, [])

  const getFieldDetail = () => {
    axios
      .get(`/customFields/${params.id}`)
      .then(res => {
        console.log('res.data', res.data)
        if (res.data && res.data.length) {
          setData({
            name: res.data[0].name,
            displayName: res.data[0].displayName,
            mandatory: !!res.data[0].mandatory,
            showOnDashboard: !!res.data[0].showOnDashboard,
            uploadMapping: !!res.data[0].uploadMapping
          })
          setMappingExist(!!res.data[0].uploadMapping)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  const fileUpload = file => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        const lines = text.split('\n').filter(v => v)
        const headers = lines[0].split(',').map(v => v.trim())
        const isValid = ['id', 'name'].every(column => headers.includes(column))
        if (isValid) {
          const rows = parseCSV(lines, headers)
          setFile(file)
          setMappings(rows)
        } else {
          setFile(null)
          Swal.fire({title: 'Select file a csv file with id and name mappings', confirmButtonColor: '#3085d6'})
        }
      }

      fileReader.readAsText(file)
    } else {
      setFile(null)
      Swal.fire({title: 'Select file a csv file with id and name mappings', confirmButtonColor: '#3085d6'})
    }
  }

  function parseCSV(lines, headers) {
    const rows = lines.slice(1).map(line => {
      const values = line.split(',')
      const obj = headers.reduce((acc, header, i) => {
        acc[header.trim()] = values[i].trim()
        return acc
      }, {})
      return obj
    })
    return rows
  }

  const isValidData = () => {
    const {name, displayName, uploadMapping, changeMapping} = fieldData
    let isValid = true
    let message = ''

    if (name === '') {
      isValid = false
      message = 'Please enter the field name'
    } else if (displayName === '') {
      isValid = false
      message = 'Please enter the field display name'
    } else if (
      ((uploadMapping && !isEditable) || (uploadMapping && isEditable && (changeMapping || !mappingExist))) &&
      mappings.length === 0
    ) {
      isValid = false
      message = 'Please upload a field mapping CSV'
    }
    return {isValid, message}
  }

  const handleSubmit = () => {
    setLoading(true)
    const {isValid, message} = isValidData()
    if (isValid) {
      if (!isEditable) {
        axios
          .post('/customFields', {
            ...fieldData,
            ...(fieldData.uploadMapping ? {mappings} : {})
          })
          .then(res => {
            if (res.data && res.data.success) {
              history.goBack()
            } else {
              Swal.fire({title: 'Something went wrong adding field!', confirmButtonColor: '#3085d6'})
            }
            setLoading(false)
          })
          .catch(err => {
            Swal.fire({
              title: err?.response?.data?.message || 'Something went wrong adding field!',
              confirmButtonColor: '#3085d6'
            })
            setLoading(false)
            console.log(err)
          })
      } else {
        axios
          .put(`/customFields/${params.id}`, {
            ...fieldData,
            ...(fieldData.uploadMapping && (fieldData.changeMapping || !mappingExist) ? {mappings} : {})
          })
          .then(res => {
            if (res.data && res.data.success) {
              history.goBack()
            } else {
              Swal.fire({title: 'Something went wrong updating field!', confirmButtonColor: '#3085d6'})
            }
            setLoading(false)
          })
          .catch(err => {
            Swal.fire({
              title: err?.response?.data?.message || 'Something went wrong updating field!',
              confirmButtonColor: '#3085d6'
            })
            setLoading(false)
            console.log(err)
          })
      }
    } else {
      setLoading(false)
      Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" className="mb-5">
        <Link to="/customFields/">Custom Fields</Link>
        <Typography color="text.primary">{isEditable ? 'Edit Custom Field' : 'New Custom Field'}</Typography>
      </Breadcrumbs>
      <div className="newUserItem">
        <label>
          Display Name <span className="mandatory">*</span>
        </label>
        <input
          type="text"
          maxLength={50}
          onChange={e =>
            setData({
              ...fieldData,
              displayName: e.target.value,
              ...(isEditable ? {} : {name: e.target.value?.replaceAll(/[^a-zA-Z]/g, '').toLowerCase()})
            })
          }
          value={fieldData.displayName}
          placeholder="Enter the Display Name"
        />
      </div>
      <div className="newUserItem">
        <label>
          Field Key <span className="mandatory">*</span>
        </label>
        <input type="text" maxLength={50} value={fieldData.name} disabled={true} placeholder="Enter the Field Key" />
      </div>
      <div className="newUserItem">
        <label>Mandatory</label>
        <Switch checked={fieldData.mandatory} onChange={e => setData({...fieldData, mandatory: e.target.checked})} />
      </div>
      <div className="newUserItem">
        <label>Show On Dashboard</label>
        <Switch
          checked={fieldData.showOnDashboard}
          onChange={e => {
            setData({
              ...fieldData,
              showOnDashboard: e.target.checked,
              uploadMapping: !e.target.checked ? false : fieldData.uploadMapping
            })
            if (!e.target.checked) {
              setFile(null)
              setMappings([])
            }
          }}
        />
      </div>
      {fieldData.showOnDashboard && (
        <>
          <div className="newUserItem">
            <label>Upload Mappings</label>
            <Switch checked={fieldData.uploadMapping} onChange={e => setData({...fieldData, uploadMapping: e.target.checked})} />
          </div>
          {isEditable && mappingExist && (
            <div className="newUserItem">
              <label>Change Mappings</label>
              <Switch
                checked={fieldData.changeMapping}
                onChange={e => setData({...fieldData, changeMapping: e.target.checked})}
              />
            </div>
          )}
        </>
      )}
      {((!isEditable && fieldData.uploadMapping) ||
        (isEditable && fieldData.uploadMapping && (fieldData.changeMapping || !mappingExist))) && (
        <div className="newUserItem">
          <Grid container spacing={4} className="importOptions">
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              className="import-optiondiv select-method-options"
              onClick={() => browseFileRef.current.click()}
            >
              <input
                type={'file'}
                id={'csvFileInput'}
                accept={'.csv'}
                onChange={e => fileUpload(e.target.files[0])}
                ref={browseFileRef}
              />
              <span className="import-contact-p flex">
                <img src={folderIcon} className="filter-red" />
                &nbsp;&nbsp;Browse file from device
              </span>
            </Grid>
          </Grid>
          {file && (
            <Grid container spacing={4} className="importOptions">
              <Grid item xs={12} md={6} lg={6} className="import-optiondiv file-selected">
                <span>
                  <b>
                    <InsertDriveFileIcon /> {file.name}
                  </b>
                  <CheckIcon color="success" className="file-selected-check-icon" />
                </span>
              </Grid>
            </Grid>
          )}
        </div>
      )}
      <div className="newUserItem">
        <button
          className="newChannelButton align-self-end"
          // disabled={!fieldData.displayName || !fieldData.name || loading}
          onClick={handleSubmit}
        >
          {!isEditable ? 'Create' : 'Save'} Field
        </button>
      </div>
    </div>
  )
}
