import React, {useState, useRef} from 'react'
import {Input} from 'reactstrap'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
const defaultDateFilterOptions = {
  selectTimeInterval: {
    localIdentifier: 'Select time interval',
    type: '',
    name: '',
    visible: true
  },
  allTime: {
    localIdentifier: 'All Time',
    type: 'All Time',
    name: '',
    visible: true
  },
  thisYear: {
    localIdentifier: 'This Year',
    type: 'thisYear',
    name: '',
    visible: true
  },
  lastYear: {
    localIdentifier: 'Last Year',
    type: 'lastYear',
    name: '',
    visible: true
  },
  thisQuarter: {
    localIdentifier: 'This Quarter',
    type: 'thisQuarter',
    name: '',
    visible: true
  },
  lastQuarter: {
    localIdentifier: 'Last Quarter',
    type: 'lastQuarter',
    name: '',
    visible: true
  },
  last4Quarter: {
    localIdentifier: 'Last 4 Quarter',
    type: 'last4Quarter',
    name: '',
    visible: true
  },
  thisMonth: {
    localIdentifier: 'This Month',
    type: 'thisMonth',
    name: '',
    visible: true
  },
  lastMonth: {
    localIdentifier: 'Last Month',
    type: 'lastMonth',
    name: '',
    visible: true
  },
  last12Months: {
    localIdentifier: 'Last 12 Months',
    type: 'last12Months',
    name: '',
    visible: true
  },
  last7Days: {
    localIdentifier: 'Last 7 Days',
    type: 'last7Days',
    name: '',
    visible: true
  },
  last30Days: {
    localIdentifier: 'Last 30 Days',
    type: 'last30Days',
    name: '',
    visible: true
  },
  last90Days: {
    localIdentifier: 'Last 90 Days',
    type: 'last90Days',
    name: '',
    visible: true
  }
}

export const DateFilterComponent = ({onDateSelect, setStartDate, setEndDate}) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [defaultSelect, setDefaultSelect] = useState('Select time interval')
  const selectRef = useRef(null)

  const handleOtherDateSelect = option => {
    let from,
      to = null
    const today = new Date()
    switch (option.type) {
      case 'All Time':
        from = null
        to = null
        break
      case 'thisYear':
        from = new Date(today.getFullYear(), 0, 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'lastYear':
        from = new Date(today.getFullYear() - 1, 0, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear() - 1, 11, 31).toISOString().substr(0, 10)
        break
      case 'thisQuarter':
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3
        from = new Date(today.getFullYear(), quarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), quarterStartMonth + 3, 0).toISOString().substr(0, 10)
        break
      case 'lastQuarter':
        const lastQuarterStartMonth = (Math.floor(today.getMonth() / 3) - 1) * 3
        from = new Date(today.getFullYear(), lastQuarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), lastQuarterStartMonth + 3, 0).toISOString().substr(0, 10)
        break
      case 'last4Quarter':
        const last4QuarterStartMonth = (Math.floor(today.getMonth() / 3) - 3) * 3
        from = new Date(today.getFullYear(), last4QuarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), last4QuarterStartMonth + 11, 0).toISOString().substr(0, 10)
        break
      case 'thisMonth':
        from = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'lastMonth':
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        from = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1).toISOString().substr(0, 10)
        to = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0).toISOString().substr(0, 10)
        break
      case 'last12Months':
        from = new Date(today.getFullYear() - 1, today.getMonth() + 1, 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last7Days':
        from = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last30Days':
        from = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last90Days':
        from = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      default:
        break
    }
    setStartDate(from)
    setEndDate(to)
    setFromDate(from)
    setToDate(to)
  }

  const handleDateSelect = () => {
    // Apply the date selection when the Apply button is clicked
    onDateSelect(fromDate, toDate)
    setStartDate(fromDate)
    setEndDate(toDate)
    setFromDate('')
    setToDate('')
  }

  return (
    <div className="flex justify-between items-center mb-4 ">
      <div className="flex-wrap w-full flex justify-between">
        <div className="flex gap-1 items-center flex-wrap md:pt-2 pt-2">
          {/* <label className="font-bold w-100">Select Date Range:</label> */}
          <label className="font-medium text-lg font-manrope">From:</label>
          <Input
            className="border border-solid rounded-lg p-3 focus:outline-none text-gray-400"
            type="date"
            value={fromDate}
            onChange={e => {
              setDefaultSelect('Select time interval')
              setFromDate(e.target.value)
              setStartDate(e.target.value)
            }}
          />
          <label className="font-medium text-lg  font-manrope">To:</label>
          <Input
            className="border border-solid rounded-lg p-3 focus:outline-none text-gray-400"
            type="date"
            value={toDate}
            onChange={e => {
              setDefaultSelect('Select time interval')
              setToDate(e.target.value)
              setEndDate(e.target.value)
            }}
          />

          <select
            ref={selectRef}
            className="p-3 border border-solid rounded-lg bg-white text-gray-400"
            onChange={e => {
              handleOtherDateSelect(defaultDateFilterOptions[e.target.value])
              setDefaultSelect(e.target.value)
            }}
            value={defaultSelect}
          >
            {Object.keys(defaultDateFilterOptions).map(key => (
              <option key={key} value={key}>
                {defaultDateFilterOptions[key].localIdentifier}
              </option>
            ))}
          </select>
          <button
            className="min-w-[137px] bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal  px-2.5 py-[7px] rounded-xl"
            style={{color: 'white', height: 'unset', alignSelf: 'stretch'}}
            onClick={handleDateSelect} // Apply the date selection on button click
          >
            Apply
          </button>
        </div>
        <div className="flex md:pt-2 pt-2">
          <div className="flex  border border-solid rounded-lg p-3 ml-2">
            <Input type="search" className="focus:outline-none" placeholder="Search...." />
            <button className="" aria-label="search">
              <SearchOutlinedIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateFilterComponent
