import {Grid} from '@mui/material'
import {Bar, Doughnut} from 'react-chartjs-2'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import 'chart.js/auto'

const options = {
  maintainAspectRatio: false,
  responsive: true,
  devicePixelRatio: 4,
  indexAxis: 'y',
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        fontColor: 'black'
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      position: 'top'
    },
    y: {
      stacked: true,
      beginAtZero: false
    }
  },
  redraw: false
}

function formatTitle(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default function CategoryChart({category, data, displayName, fullView = false, handleChartClick, type}) {
  const titleContainer = (
    <div className="completion-container">
      <span className="response-block"></span>
      <span className="title">Sent and Failed by {displayName ? displayName : formatTitle(category)}</span>
      <div className="ms-auto cursor-pointer" onClick={() => handleChartClick(category)}>
        {fullView ? <FullscreenExitIcon className="text-black-50" /> : <FullscreenIcon className="text-black-50" />}
      </div>
    </div>
  )
  return type !== 'Pie' ? (
    <Grid item xs={4} className="small-chart-container">
      {titleContainer}
      <div style={!fullView ? {height: 400, overflowY: 'auto'} : {}}>
        <div
          style={{
            width: '100%',
            height: 350 + data.labels.length * 15
          }}
        >
          <Bar className="w-100" options={options} data={{labels: data.labels, datasets: data.datasets}} />
        </div>
      </div>
    </Grid>
  ) : (
    <Grid container xs={12} className="">
      <Grid item xs={12} className="small-chart-container">
        {titleContainer}
      </Grid>
      <Grid item xs={12} className="small-chart-container mx-auto pt-5">
        <div className="w-100">
          <Doughnut
            className={`${!fullView ? 'pie-chart-small' : 'pie-chart-large'} completion-response-chart-small`}
            options={{
              plugins: {legend: {}},
              cutout: '85%'
            }}
            data={data}
          />
        </div>
      </Grid>
    </Grid>
  )
}
