import {useState} from 'react'
import {styled} from '@mui/material/styles'

import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import ListItem from '@mui/material/ListItem'
import userProfile from '../images/60111.jpg'
import {useLocation} from 'react-router-dom'
import {useAppState} from '../../state'
import SidebarMenu from '../Sidebar-MenuItem/SidebarMenu'
import SidebarLogoClose from '../../components/images/icon.svg'
import SidebarLogoOpen from '../../components/images/ProntobyKaptea.svg'
import logoutIcon from '../../components/images/logoutivon.svg'
import Headermenus from '../headermenus/Headermenus'
import IconButton from '@mui/material/IconButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import MenuIconSVG from '../../components/images/menu.svg'

import CloseIcon from '@mui/icons-material/Close'
const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  background: 'transparent',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const NewSidebar = props => {
  const {setOpen, open, routes, toggleOpen, setToggleOpen} = props
  const location = useLocation()
  const {user, signOut} = useAppState()
  const {settings = {}} = user
  const {enable_contacts = false} = settings
  const [menuId, setMenuId] = useState('')
  const [openProfile, setOpenProfile] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleToggleOpen = () => {
    setToggleOpen(true)
  }
  const handleToggleClose = () => {
    setToggleOpen(false)
  }
  const headerRoutesConfig = [
    {
      path: ' ',
      headerTitle: 'Activity Dashboard',
      headerDescription: 'View all recent application activity.'
    },
    {
      path: 'plans',
      headerTitle: 'Activity Plans',
      headerDescription: 'View all plans'
    },
    // {
    //   path: "Logs",
    //   headerTitle: "User Activity Log",
    //   headerDescription: "View user activity.",
    // },
    {
      path: 'profile',
      headerTitle: 'User Profile',
      headerDescription: 'View user profile.'
    },
    {
      path: 'settings',
      headerTitle: 'Account Settings',
      headerDescription: 'View user settings.'
    },
    {
      path: 'feedback',
      headerTitle: 'Leave Feedback',
      headerDescription: 'Leave user feedback.'
    },
    {
      path: 'support',
      headerTitle: 'Support & Help',
      headerDescription: 'Get support and help.'
    },
    {
      path: 'Channels',
      headerTitle: 'Your Channels',
      headerDescription: 'View Channels.'
    },
    {
      path: 'NewChannel',
      headerTitle: 'Add Channels',
      headerDescription: 'Add a new channel.'
    },
    {
      path: 'SMS',
      headerTitle: 'SMS Management',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'Email',
      headerTitle: 'Email Management',
      headerDescription: 'Email Area.'
    },
    {
      path: 'SMS-Channel',
      headerTitle: 'SMS Channel Management',
      headerDescription: 'SMS Channels.'
    },
    {
      path: 'EmailChannel',
      headerTitle: 'Email Channel Management',
      headerDescription: 'Email Channels.'
    },
    {
      path: 'scheduleSMSChannel',
      headerTitle: 'Schedule SMS Channel'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'sendSMSChannel',
      headerTitle: 'Send SMS'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'scheduleEmailChannel',
      headerTitle: 'Schedule Email'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'sendEmailChannel',
      headerTitle: 'Send Email'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'SMS',
      headerTitle: 'SMS Management',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'whatsapp',
      headerTitle: 'WhatsApp Dashboard',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'whatsapp-archives',
      headerTitle: 'WhatsApp Archives',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'WhatsappUI',
      headerTitle: 'WhatsApp ui'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    // {
    //   path: "Chat",
    //   headerTitle: "Web Chat",
    //   // headerDescription: "Add, Edit and View Super Admins and Admins.",
    // },
    {
      path: 'users',
      headerTitle: 'User Management'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'user-edit/',
      headerTitle: 'Edit User'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'user-view/',
      headerTitle: 'View User'
      // headerDescription: "View Super Admins, Admins and Staff User Accounts.",
    },
    {
      path: 'templates',
      headerTitle: 'SMS & Email Templates',
      headerDescription: 'Health and safety laws apply to all employers, self-employed people and employees in their workplaces.'
    },
    {
      path: 'newUser',
      headerTitle: 'Add New User'
      // headerDescription:
      //   "Add and Edit Super Admins, Admins and Staff User Accounts.",
    },
    {
      path: 'sendSMS',
      headerTitle: 'Send Bulk & Single SMS'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'scheduleSMS',
      headerTitle: 'Schedule Bulk & Single SMS'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'sendEmail',
      headerTitle: 'Send Bulk & Single Email'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'scheduleEmail',
      headerTitle: 'Schedule Bulk & Single Emails'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'schedule',
      headerTitle: 'Schedule Emails'
      // headerDescription:
      //   "Based on the Principles of Hazard Analysis and Critical Control Point (HACCP)",
    },
    {
      path: 'rams',
      headerTitle: 'Risk Assesment Method Statement(RAMS)'
      // headerDescription:
      //   "Health and safety laws apply to all employers, self-employed people and employees in their workplaces.",
    },
    {
      path: 'analytics',
      headerTitle: 'Analytics',
      headerDescription: 'View all sent campaigns.'
      //menuIcon: <BarChartOutlined />
    },
    {
      path: 'campaigns-view/',
      headerTitle: 'View Campaign',
      headerDescription: 'View SMS campaign and sent sms.'
      ///menuIcon: <BarChartOutlined />
    },
    {
      path: 'email-campaign/',
      headerTitle: 'View Campaign',
      headerDescription: 'View email campaign and sent emails.'
      //menuIcon: <BarChartOutlined />
    },
    {
      path: 'voice-channel',
      headerTitle: 'Voice Channel'
    },

    {
      path: 'newtemplate',
      headerTitle: 'Add New Template',
      headerDescription: 'Create default SMS templates.'
    },
    {
      path: 'template-edit/',
      headerTitle: 'Edit Template',
      headerDescription: 'Add, Edit and View Default SMS Templates.'
    },
    {
      path: 'template-view/',
      headerTitle: 'View Template',
      headerDescription: 'Add, Edit and View Default SMS Templates.'
    },
    /* {
       path: "contacts",
       headerTitle: "Contact List",
       headerDescription: "View Contact List.",
     },
     {
       path: "create-contact-list",
       headerTitle: "Contact List",
       headerDescription: "Add, Edit and View Contact List.",
     },
     {
       path: "edit-contact-list/",
       headerTitle: "Contact List",
       headerDescription: "Add, Edit and View Contact List.",
     },
     {
       path: "newContact",
       headerTitle: "Contact",
       headerDescription: "Add, Edit and View Contact.",
     },
     {
       path: "contact-edit/",
       headerTitle: "Contact",
       headerDescription: "View Tasks",
     },*/
    {
      path: 'tassks',
      headerTitle: 'Tasks',
      headerDescription: 'Add, Edit and View Contact.'
    }
  ]
  const filteredCurrentPath = headerRoutesConfig.filter(data => location.pathname.substring(1).startsWith(data.path))

  const currentPath =
    filteredCurrentPath && filteredCurrentPath.length ? filteredCurrentPath : headerRoutesConfig.filter(data => data.path === ' ')
  let sidebarRoutes = routes.filter(route => !!route.icon)

  if (!enable_contacts) {
    sidebarRoutes = sidebarRoutes.filter(route => route.group != 'contact')
  }
  return (
    <>
      <div>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            background: {
              xs: '#F9FAFC',
              sm: '#F9FAFC',
              lg: 'white',
              md: 'white'
            },
            /*  boxShadow: {
              xs: '0 8px 6px -6px #D4D6DA !important',
              sm: '0 8px 6px -6px #D4D6DA !important',
              lg: 'none !important',
              md: 'none !important'
            }, */
            borderBottom: {
              xs: '1px solid #F0EDEE !important',
              sm: '1px solid #F0EDEE !important',
              lg: 'none !important',
              md: 'none !important'
            },
            width: {
              xs: '100%',
              sm: '100%',
              lg: open ? 'calc(100% - 239px)' : 'calc(100% - 64px)',
              md: open ? 'calc(100% - 239px)' : 'calc(100% - 64px)'
            },
            borderLeft: {
              lg: '1px solid rgba(2, 2, 2, 0.12)',
              md: '1px solid rgba(2, 2, 2, 0.12)',
              sm: 'none',
              xs: 'none'
            },
            zIndex: {lg: 1201, md: 1201, sm: 0, xs: 0}
          }}
        >
          <Toolbar>
            <div className="w-full">
              <div className={`w-full flex justify-between`}>
                <div className="hidden lg:flex md:flex lg:justify-center lg:items-center md:justify-center md:items-center ">
                  <div className="text-black text-2xl font-bold">{currentPath[0].headerTitle}</div>
                </div>
                <div className="flex justify-center items-center lg:hidden md:hidden">
                  <IconButton
                    color="black"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleToggleOpen}
                    sx={{
                      ...(toggleOpen && {display: 'none'}),
                      paddingTop: '20px !important',

                      '&:hover': {
                        background: 'none'
                      }
                    }}
                  >
                    <img src={MenuIconSVG} width={30} height={30} alt="sidebar-menu-icon" />
                  </IconButton>
                  <img src={SidebarLogoOpen} alt="sidebar-img" className="h-[51px] max-w-full pl-2" />
                </div>
                <div className="hidden md:flex text-black w-[216px] h-[60px] border border-solid rounded-lg mt-[7px] mb-3 p-1">
                  <div className="flex items-center w-full p-1">
                    <div className="border border-transparent rounded-md overflow-hidden w-[48px] h-[48px]">
                      <img src={SidebarLogoClose} alt="profile-img" className="object-cover object-center w-full h-full" />
                    </div>
                    <div className="flex w-full p-1">
                      <div className="w-full flex flex-col justify-start">
                        <div className="flex justify-between w-full">
                          <div className="font-medium text-sm text-left">{user.fullName}</div>
                          <div className="flex items-center cursor-pointer" onClick={() => setOpenProfile(!openProfile)}>
                            <i className={`fa-solid fa-chevron-down text-base ${!openProfile ? 'rotate-180' : ''}`}></i>
                          </div>
                        </div>
                        <div className="font-medium text-sm text-left">{user.role}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openProfile && <Headermenus setOpenProfile={setOpenProfile} />}
            </div>
          </Toolbar>
        </AppBar>

        <div className="hidden md:block lg:block">
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{justifyContent: 'start !important'}}>
              <div className="hidden lg:block md:block pt-[20px]">
                {open ? (
                  <div className="w-full cursor-pointer" onClick={() => setOpen(!open)}>
                    <img src={SidebarLogoOpen} alt="sidebar-img" className="h-[51px] max-w-full pl-2 " />
                  </div>
                ) : (
                  <div color="inherit" aria-label="open drawer" className="cursor-pointer " onClick={handleDrawerOpen}>
                    <img src={SidebarLogoClose} alt="closeMenu" />
                  </div>
                )}
              </div>
            </DrawerHeader>

            <List sx={{paddingTop: '18px !important', maxHeight: 'calc(100vh - 106px)', overflow: 'auto', overflowX: 'hidden'}}>
              <ListItem disablePadding sx={{display: 'block !important', padding: '0px !important'}}>
                {sidebarRoutes.map((route, index) => {
                  return (
                    <SidebarMenu
                      route={route}
                      index={index}
                      open={open}
                      menuId={menuId}
                      setMenuId={setMenuId}
                      setOpen={setOpen}
                      currentPath={location.pathname}
                    />
                  )
                })}
              </ListItem>
            </List>

            <div
              className="w-full flex px-5 absolute bottom-2 cursor-pointer hover:bg-[#f0edee] pl-[20px] pr-[10px] py-2"
              onClick={signOut}
            >
              <div className="w-full flex flex-row">
                <img src={logoutIcon} alt="logout" />
                {open && <div className="font-poppins pl-[10px] text-[#62585D]">Log Out</div>}
              </div>
            </div>
          </Drawer>
        </div>
        <div className="block md:hidden lg:hidden ">
          <SwipeableDrawer anchor={'left'} open={toggleOpen} onClose={handleToggleClose} onOpen={handleToggleOpen}>
            <List
              sx={{
                paddingTop: '18px !important',
                maxHeight: 'calc(100vh - 106px)',
                overflow: 'scroll',
                width: '280px !important'
              }}
            >
              <ListItem disablePadding sx={{display: 'block', padding: '0px !important'}}>
                <div className="flex justify-between pb-[36px]">
                  <div>
                    <img src={SidebarLogoOpen} alt="mobile-view-img" className="h-[51px] max-w-full pl-[30px]" />
                  </div>
                  <div className="flex justify-center items-center cursor-pointer pr-3" onClick={handleToggleClose}>
                    <CloseIcon
                      sx={{
                        border: '1px solid #2C2126 !important',
                        borderRadius: '30px',
                        width: '22px',
                        height: '21px',
                        textAlign: 'center'
                      }}
                    />
                  </div>
                </div>
                {sidebarRoutes.map((route, index) => {
                  return (
                    <SidebarMenu
                      route={route}
                      index={index}
                      open={open}
                      menuId={menuId}
                      setMenuId={setMenuId}
                      setOpen={setOpen}
                      currentPath={location.pathname}
                      handleToggleClose={handleToggleClose}
                      mobileView={true}
                    />
                  )
                })}
              </ListItem>
            </List>
            <div
              className="w-full flex px-5 absolute bottom-2 cursor-pointer hover:bg-[#f0edee] pl-[16px] pr-[10px] py-2"
              onClick={signOut}
            >
              <div className="w-full flex flex-row lg:p-0 md:p-0 pl-[35px]">
                <img src={logoutIcon} alt="logout" />
                {open && <div className="font-poppins pl-[10px] text-[#62585D]">Log Out</div>}
              </div>
            </div>
          </SwipeableDrawer>
        </div>
      </div>
    </>
  )
}

export default NewSidebar
